import * as Msal from '@azure/msal-browser'
import $ from 'jquery'
import * as Papa from 'papaparse'
import 'datatables.net'

let msClient = new Msal.PublicClientApplication({
    auth: {
        clientId: 'cdf39a22-262c-42cb-a9a0-37940439412e',
        authority:
            'https://login.microsoftonline.com/d22d141f-ae37-447f-acfa-2e1d0e5b4969/',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
})

let authResult: Msal.AuthenticationResult

export function getAuthenticationResult() {
    return authResult
}

export function signIn(state: string) {
    msClient.acquireTokenRedirect({
        scopes: ['https://graph.microsoft.com/.default'],
        state: state,
    })
}

export function signOut() {
    msClient.logoutRedirect({ account: msClient.getActiveAccount() })
}

export async function handleRedirectPromiseOrAcquireTokenSilent() {
    let redirectResult = await msClient.handleRedirectPromise(),
        silentResult

    if (msClient.getAllAccounts().length == 1) {
        msClient.setActiveAccount(msClient.getAllAccounts()[0])
    } else {
        return false
    }

    if (redirectResult && redirectResult.accessToken) {
        authResult = redirectResult
        return true
    }

    try {
        silentResult = await msClient.acquireTokenSilent({
            scopes: ['https://graph.microsoft.com/.default'],
        })
    } catch (err) {
        return false
    }

    if (silentResult && silentResult.accessToken) {
        authResult = silentResult
        return true
    } else {
        return false
    }
}

interface azFetchConfig {
    url?: string
    method?: string
    body?: any
    tokenScope?: string
    additionalHeaders?: any
}

export async function azFetch(request: azFetchConfig) {
    let token = authResult.accessToken
    if (request.tokenScope) {
        let newToken = await msClient.acquireTokenSilent({
            scopes: [request.tokenScope],
        })
        token = newToken.accessToken
    }

    let url = request.url || 'https://graph.microsoft.com/v1.0/me/',
        accessToken = token

    let headers = {
        'x-ms-date': new Date().toUTCString(),
        'x-ms-version': '2021-06-08',
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        //'Accept': 'application/json;odata.metadata=minimal'
        Accept: 'application/json',
    }

    Object.getOwnPropertyNames(request.additionalHeaders || {}).forEach(
        (property) => {
            headers[property] = request.additionalHeaders[property]
        }
    )

    let stringified: string

    if (request.body) {
        stringified = JSON.stringify(request.body)
        headers['Content-Length'] = stringified.length
    }

    let config = {
        body: stringified! || null,
        headers: new Headers(headers),
        method: request.method || 'get',
    }

    return await fetch(url, config)
}

export async function fetchMSGraphJsonRequest(URI: string) {
    let response = await azFetch({
        url: 'https://graph.microsoft.com/v1.0' + URI,
    })

    return response.json()
}

export async function getUserProfile(mail: string) {
    return fetchMSGraphJsonRequest('/users/' + mail)
}

export async function getUserPhotoURL(mail: string) {
    let url = 'https://graph.microsoft.com/v1.0/users/'
    url += mail.replace('cn.arnoldporter.com', 'arnoldporter.com')
    url += '/photo/$value'

    let response = await this.azFetch({
        url: url,
        additionalHeaders: {
            'Content-Type': 'image/jpg',
        },
    })

    if (response.status == 200) {
        let photoData = await response.blob(),
            url = window.URL || window.webkitURL
        return url.createObjectURL(photoData)
    } else {
        throw 'Cannot fetch photo'
    }
}

export async function getPrivateJsonFile(blobPath: string) {
    let response = await azFetch({
        url: 'https://adminappst.blob.core.windows.net/private/' + blobPath,
        tokenScope: 'https://storage.azure.com/user_impersonation',
    })

    return await response.json()
}

export function parseCSV(csvString: string) {
    return new Promise((resolve, reject) => {
        Papa.parse(csvString, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                resolve(results.data)
            },
            error: (error) => {
                reject(error)
            },
        })
    })
}

export async function getPrivateCsvFile(blobPath: string) {
    let response = await azFetch({
        url: 'https://adminappst.blob.core.windows.net/private/' + blobPath,
        tokenScope: 'https://storage.azure.com/user_impersonation',
    })

    let blob = await response.blob(),
        text = await blob.text()

    return parseCSV(text)
}

export async function getFirmSearchCacheData() {
    return getPrivateCsvFile('FirmSearchCacheData.csv')
}

export async function newAdminAppRequest(request) {
    let fetchConfig = {
        method: 'post',
        url: 'https://adminappst.table.core.windows.net/Jobs',
        tokenScope: 'https://storage.azure.com/user_impersonation',
        body: {
            PartitionKey: 'AdminApp',
            RowKey: crypto.randomUUID(),
            Request: JSON.stringify(request),
            Token: authResult.idToken,
        },
    }

    let response = await azFetch(fetchConfig)

    return {
        id: fetchConfig.body.RowKey,
        response: await response.json(),
    }
}

export async function getAdminAppResult(id) {
    if (id == undefined) {
        throw 'Please provide ID'
    }

    let response = await azFetch({
        url:
            'https://adminappst.blob.core.windows.net/private/JobResults/' +
            id +
            '.json',
        tokenScope: 'https://storage.azure.com/user_impersonation',
    })

    return await response.json()
}

export function DisplayTable(data, config) {
    config = config || {}

    let location = config.location || '#response',
        dom = config.dom || "<'grid'<l><f>><t><'grid'<i><'csvButton'>>",
        showAll = config.showAll

    $(location).html('').show()

    if (typeof data == 'string') {
        $('<div>').text(data).appendTo(location)
        return
    }
    if (data === undefined) {
        $('<div>').text('No data.').appendTo(location)
        return
    }

    data = Array.isArray(data) ? data : [data]

    try {
        let headers = Object.getOwnPropertyNames(data[0]).map((item) => {
            return { title: item, data: item }
        })

        var tableOptions: DataTables.Settings = {
            data: data,
            order: [],
            columns: headers,
            dom: dom,
            lengthMenu: [
                [10, 25, 50, 100, -1],
                [10, 25, 50, 100, 'All'],
            ],
        }
        // if (showAll) {
        //     tableOptions.iDisplayLength = -1
        // }

        var table = $('<table>')
            .appendTo(location)
            .css('width', '100%')
            .DataTable(tableOptions)

        $(location)
            .find('.dataTables_filter input')
            .detach()
            .appendTo($(location).find('.dataTables_filter'))
        $(location)
            .find('.dataTables_filter input')
            .attr('placeholder', 'Search Table')
        $(location).find('.dataTables_filter label').remove()
        $(location).find('select').css('width', 'Auto')

        let tableContainer = $(location).find('table').parent()
        let figure = $('<figure>').appendTo(tableContainer)
        tableContainer.find('table').detach().appendTo(figure)

        function convertToCSV(arr) {
            const array = [Object.keys(arr[0])].concat(arr)

            return array
                .map((it) => {
                    return '"' + Object.values(it).join('","').toString() + '"'
                })
                .join('\r\n')
        }
        function download(filename, text) {
            var element = document.createElement('a')
            element.setAttribute(
                'href',
                'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
            )
            element.setAttribute('download', filename)

            element.style.display = 'none'
            document.body.appendChild(element)

            element.click()

            document.body.removeChild(element)
        }

        let tableData = table.rows().data()
        let saveCSV = $('<button>')
            .addClass('secondary outline')
            .text('Save Table as CSV')
            .appendTo('.csvButton')
        $('<i>').addClass('fa fa-download').prependTo(saveCSV)
        saveCSV.on('click', (event) => {
            let csvString = convertToCSV(tableData.toArray())
            download('table.csv', csvString)
        })

        return table
    } catch (error) {
        $('<div>')
            .text('Error: ' + error)
            .appendTo(location)
    }
}

export async function forEachIn(array, func) {
    if (array === void 0 || array === null) {
        throw new TypeError()
    }
    if (typeof func !== 'function') {
        throw new TypeError()
    }

    let inputArray = Object(array)

    return await Promise.all(inputArray.map(async (item) => await func(item)))
}
